.booking-container {
    max-width: 800px;
    margin: 0 auto;
    font-family: Arial, sans-serif;
    color: #333;
  }
  
  .tabs {
    display: flex;
    justify-content: space-around;
    margin-bottom: 20px;
    border-bottom: 2px solid #b1b1b1;
  }
  
  .tabs button {
    background: none;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    font-weight: bold;
    color: #333;
    border-bottom: 2px solid transparent;
  }
  
  .tabs button.active {
    color: orange;
    border-bottom: 2px solid orange;
  }
  
  .tab-content {
    padding: 20px;
    border: 1px solid #ddd;
    background-color: #f9f9f9;
    border-radius: 5px;
  }
  
  .tab-content p {
    margin: 10px 0;
  }
  
  .booking-details,
  .passenger-details,
  .ride-details,
  .payment-details {
    font-size: 16px;
  }
  
  h1 {
    text-align: center;
    color: black;
    margin-bottom: 20px;
  }
  
  .payment-details {
    background: #f9f9f9;
    padding: 20px;
    border-radius: 8px;
    border: 1px solid #ddd;
    margin-top: 10px;
  }
  
  .payment-details p {
    margin: 5px 0;
    font-size: 16px;
    color: #333;
  }
  
  .payment-details ul {
    list-style: none;
    padding: 0;
    margin-top: 10px;
  }
  
  .payment-details li {
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 10px;
    margin-bottom: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }
  
  .payment-details li p {
    margin: 5px 0;
    font-size: 14px;
    color: #555;
  }
  
  .payment-details li p b {
    color: #333;
  }
  
  .payment-details .payment-method {
    font-size: 18px;
    font-weight: bold;
    color: orange;
    margin-bottom: 10px;
  }
  
  .payment-details button {
    margin-top: 15px;
    background: orange;
    color: #fff;
    border: none;
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .payment-details button:hover {
    background: #e67e22;
  }
  
  .payment-details .cash-deposit {
    margin: 10px 0;
    font-size: 14px;
    color: green;
    font-weight: bold;
  }
  
  .ride-details {
    background: #f9f9f9;
    padding: 20px;
    border-radius: 8px;
    border: 1px solid #ddd;
    margin-top: 10px;
  }
  
  .ride-details h1 {
    font-size: 20px;
    color: orange;
    margin-bottom: 15px;
    border-bottom: 2px solid #ddd;
    padding-bottom: 5px;
  }
  
  .ride-details div {
    margin-bottom: 10px;
  }
  
  .ride-details label {
    font-weight: bold;
    color: #333;
    margin-right: 5px;
  }
  
  .ride-details span {
    font-size: 14px;
    color: #555;
  }
  
  .ride-details .section {
    margin-top: 20px;
    padding: 15px;
    background: #fff;
    border-radius: 8px;
    border: 1px solid #ddd;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }
  
  .ride-details .section h1 {
    font-size: 18px;
    color: #333;
    margin-bottom: 10px;
  }
  
  .ride-details .section div {
    margin-bottom: 8px;
  }
  
  .ride-details .pending {
    font-size: 14px;
    color: #888;
    font-style: italic;
    margin-top: 10px;
  }

  .description {
    font-size: 12px;
    color: #777;
    font-style: italic;
    margin-top: 5px;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .booking-container {
      padding: 10px;
    }
  
    .tabs {
      flex-direction: column;
      gap: 10px;
    }
  
    .tabs button {
      padding: 10px;
      font-size: 14px;
    }
  
    .tab-content {
      padding: 15px;
    }
  
    .payment-details, .ride-details {
      padding: 15px;
    }
  
    .payment-details li, .ride-details .section {
      padding: 10px;
    }
  
    h1 {
      font-size: 18px;
      margin-bottom: 15px;
    }
  
    .ride-details h1, .payment-details .payment-method {
      font-size: 16px;
      text-align: center;
    }
  
    .ride-details label, .payment-details li p {
      font-size: 14px;
      display: block; /* Stack labels and values */
      margin-bottom: 5px;
    }
  
    .ride-details span, .payment-details li p span {
      display: block;
      font-size: 14px;
      margin-bottom: 10px;
    }
  
    .tabs button.active {
      font-size: 15px;
      color: orange;
    }
  }
  