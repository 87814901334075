.app-container {
    display:flexbox;
  }
  
  .address-list,
  .address-form {
    flex: 1;
    padding: 20px;
  }
  
  .address-list {
    background-color: #f5f5f5;
    border-right: 1px solid #ccc;
  }
  
  .address-form {
    background-color: #ffffff;
  }

  /* Style the form - display items horizontally */
.form-inline {
  display: inline;
  flex-flow: row wrap;
  align-items: center;
  position: relative;
}

/* Add some margins for each label */
.form-inline label {
  margin: 5px 10px 5px 0;
}

/* Style the input fields */
.form-inline input {
  vertical-align: middle;
  margin: 5px 10px 5px 0;
  padding: 10px;
  background-color: #fff;
  border: 1px solid #ddd;
}

/* Add responsiveness - display the form controls vertically instead of horizontally on screens that are less than 800px wide */
@media (max-width: 800px) {
  .form-inline input {
    margin: 10px 0;
  }

  .form-inline {
    flex-direction: column;
    align-items: stretch;
  }
}
  