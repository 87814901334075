.client-card {
  display: flex;
  flex-direction: column;
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgb(0 0 0 / 46%);
  transition: transform 0.2s;
  max-width: 350px;
  height: 300px; /* Set a consistent height for the card */
}

.client-card:hover {
  transform: scale(1.02);
}

.client-card .client-image {
  background-color: #fff2e0;
  object-fit: contain;
  width: 100%; /* Full width of the container */
  height: 50%; /* Half the height of the card */
  border-top-left-radius: 5px; /* Optional: Rounded corners for top-left */
  border-top-right-radius: 5px; /* Optional: Rounded corners for top-right */
  display: block; /* Ensure the image is a block element */
}

.client-details {
  height: 50%; /* Take the other half of the card */
  padding: 16px;
  display: flex;
  background: white;
  flex-direction: column;
  justify-content: center; /* Centers the text vertically */
}

.client-details h3 {
  margin: 0 0 8px;
  font-size: 1.1rem;
  color: #333;
  font-weight: bold;
}

.client-details .book-button {
  background-color: #fcb83a; /* Button background */
  color: #192616; /* Text color */
  padding: 0.4rem 1rem; /* Button padding */
  border-radius: 5px; /* Rounded corners */
  text-decoration: none; /* No underline */
  font-weight: bold; /* Bold text */
  display: inline-block; /* Ensure proper spacing and layout */
  margin-top: 1.5rem; /* Add spacing from the preceding content */
  text-align: center;
  width: 100%;
  max-width: 220px;
  align-self: center;
}

.client-details .book-button:hover {
  background-color: #e3a022; /* Darker shade on hover */
}

.filter-input {
  display: block;
  padding: 8px;
  margin-left: 5px;
  width: 80%;
  border: 1px solid #ddd;
  border-radius: 4px;
  border: none !important;
}

.client-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));
  gap: 16px;
  padding: 16px 0;
}

.functionality-availability {
  font-size: 1.2rem;
  margin-bottom: 1.5rem;
  font-style: italic;
}

@media (max-width: 768px) {
  .client-card {
    flex-direction: row;
    height: auto;
    max-width: 100%;
  }

  .client-grid {
    grid-template-columns: 1fr;
  }

  .client-card .client-image {
    width: 40%;
    height: auto;
  }

  .client-details {
    height: auto;
    width: 100%;
  }
}

.client-details .description {
  margin-top: 0;
  font-size: 13px;
}

.search-input-container {
  display: flex;
  align-items: center;
  width: 100%;
  background: white;
  border-radius: 10px;
  padding: 0 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.search-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 1rem;
  margin-bottom: 2rem;
  background: #ffffff96;
  padding: 0.3rem;
  border-radius: 7px;
}

@media (max-width: 768px) {
  .search-container {
    margin-bottom: 1rem;
  }
}
