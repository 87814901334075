/* General Reset */
body, h1, h2, p, a {
    margin: 0;
    padding: 0;
    font-family: 'Arial', sans-serif;
  }
  
  .ride-with-us-page {
    background-color: #f9f9f9;
    color: #222;
    line-height: 1.6;
  }
  
  /* Hero Section */
  .driver-hero-section {
    position: relative;
    background-image: url('./trusted_partners.png'); /* Replace with your image */
    background-size: cover;
    background-position: center;
    height: 500px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  
  .driver-hero-section::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 1;
  }
  
  .hero-content {
    max-width: 600px;
    z-index: 2;
  }
  
  .hero-content h1 {
    font-size: 2.5rem;
    margin-bottom: 1rem;
    font-weight: bold;
    color: #fcb83a;
  }

  .hero-content h2 {
    font-size: 1.5rem;
    margin-top: 1rem;
    font-weight: bold;
    font-style: italic;
    color: #fcb83a;
  }
  
  .hero-content p {
    font-size: 1.2rem;
    margin-bottom: 1.5rem;
  }
  
  .hero-content .hero-button {
    background-color: #fcb83a;
    color: #192616;
    padding: 0.75rem 1.5rem;
    margin: 1em 1rem 0 0;
    border-radius: 5px;
    text-decoration: none;
    font-weight: bold;
    size: 1.2em;
    display: inline-block;
  }
  
  .hero-content .hero-button:hover {
    background-color: #e3a022;
  }
  
  /* Card Section */
  .section-title {
    text-align: center;
    margin: 2rem 0 1rem;
    font-size: 2rem;
    font-weight: bold;
    color: #192616;
  }
  
  .cards-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 2rem;
    padding: 2rem;
  }
  
  .card {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    text-align: center;
    padding: 1.5rem;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .card:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
  }
  
  .card-image {
    width: 100px;
    height: 100px;
    margin: 0 auto 1rem;
  }
  
  .card-title {
    font-size: 1.5rem;
    font-weight: bold;
    color: #192616;
    margin-bottom: 0.5rem;
  }
  
  .card-description {
    font-size: 1rem;
    color: #555;
    margin-bottom: 1rem;
  }
  
  /* Unique Selling Point Section */
  .unique-selling-point {
    text-align: center;
    background-color: #fff;
    padding: 2rem;
    border-top: 1px solid #ddd;
  }
  
  .usp-description {
    font-size: 1.2rem;
    margin: 1rem 0;
    color: #192616;
  }

  .usp-description-italic {
    font-size: 1.2rem;
    margin: 1rem 0;
    color: #f7a50b;
  }

  .usp-example-text {
    font-size: 1rem;
    color: #555;
  }
  
  .usp-image {
    margin-top: 1.5rem;
    max-width: 80%;
    height: auto;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }

  .usp-image:hover {
    transform: translateY(-10px);
    box-shadow: 0 12px 16px rgba(0, 0, 0, 0.3);
  }
  

  .ride-with-us-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .ride-with-us-modal-content {
    position: relative;
    background-color: #fff;
    border-radius: 10px;
    padding: 1rem;
    max-width: 80%;
    max-height: 80%;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
    text-align: center;
    overflow-y: auto;}
  
  .ride-with-us-modal-image {
    width: 100%;
    height: auto;
    border-radius: 10px;
    }
  
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: #fcb83a;
    border: none;
    border-radius: 5px;
    color: #192616;
    padding: 0.5rem 1rem;
    cursor: pointer;
    font-weight: bold;
  }
  
  .close-button:hover {
    background-color: #e3a022;
  }
  