/* Wrapper for the entire component */
.login-register-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 400px;
    margin: auto;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    font-family: Arial, sans-serif;
  }
  
  /* Tab header */
  .tab-header {
    display: flex;
    width: 100%;
    background-color: #192616;
  }
  
  .tab-button {
    flex: 1;
    padding: 1rem;
    text-align: center;
    font-size: 1rem;
    font-weight: bold;
    color: #fff;
    background-color: transparent;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .tab-button.active {
    background-color: #fcb83a;
    color: #192616;
  }
  
  .tab-button:hover {
    background-color: #e3a022;
  }
  
  /* Tab content */
  .tab-content {
    width: 100%;
    padding: 2rem;
    box-sizing: border-box;
  }
  
  /* Form styling */
  form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
  }
  
  label {
    display: flex;
    flex-direction: column;
    font-size: 0.9rem;
    color: #192616;
  }
  
  input {
    padding: 0.75rem;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 1rem;
  }
  
  input:focus {
    border-color: #fcb83a;
    outline: none;
  }
  
  button[type="submit"] {
    background-color: #fcb83a;
    color: #192616;
    padding: 0.75rem 1.5rem;
    border-radius: 5px;
    text-decoration: none;
    font-weight: bold;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  button[type="submit"]:hover {
    background-color: #e3a022;
  }
  
  /* Message Content */
  .message-content {
    margin-top: 1rem;
    padding: 0.75rem;
    border-radius: 5px;
    font-size: 0.9rem;
  }
  
  .message-content.info {
    background-color: #eaf5ea;
    color: #2e7d32;
  }
  
  .message-content.error {
    background-color: #fbeaea;
    color: #c62828;
  }
  