/* Hero Section */
.heroSectionBusinessCard {
  background-image: url("./Business_card_order_Background.jpeg");
  height: 400px;
}

.top-margin {
  margin-top: 20px;
}

.details .text-3xl {
  padding: 20px 20px 20px 0;
}
