.contact-card-header {
  text-align: center;
}

.contact-card-header h1 {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 1rem 0;
  font-size: 2rem;
  text-align: justify;
  gap: 1rem;
}

.contact-card-header p {
  margin-bottom: 1rem;
}

.contact-card {
  padding: 1em;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #fff2e042;
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
}

.contact-item {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.icon {
  margin-right: 10px;
  color: #ff9800;
  font-size: 1.2em;
}

.icon-large {
  font-size: 1.9em; /* Slightly larger size for WhatsApp and Viber icons */
}

.contact-link {
  color: #007bff;
  text-decoration: none;
  font-weight: bold;
  /* margin-left: 10px; */
}

.contact-link:hover {
  text-decoration: underline;
}

.whatsapp-link {
  color: #25d366;
}

.whatsapp-link:hover {
  color: #128c7e;
}

.viber-link {
  color: #7360f2;
  margin-left: 5px;
  font-size: 1.77em;
}

.viber-link:hover {
  color: #582bcd;
}

.contact-item.payment-methods {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.payment-methods p {
  font-size: 11px;
  font-style: italic;
  margin-bottom: 3px;
}

.payment-icons {
  display: flex;
  justify-content: center;
  gap: 10px;
  font-size: 0.9rem;
}

.payment-icons svg {
  margin: 0;
}
