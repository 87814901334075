.darkBG {
  background-color: rgba(0, 0, 0, 0.6);
  width: 100%;
  height: 100%;
  z-index: 999;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: fixed;
}

.centered {
  position: fixed;
  height: 80%;
  /* overflow: hidden; */
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
  background: white;
  border-radius: 16px;
}

.modalH {
  width: 550px;
  height: 100%;
  border-radius: inherit;
  background: white;
  color: white;
  display: flex;
  flex-direction: column;
  z-index: 5;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.04);
}

@media (max-width: 700px) {
  .modalH {
    width: 350px;
  }
}

.modalHeader {
  height: 50px;
  background: white;
  overflow: hidden;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}

.heading {
  margin: 0;
  padding: 10px;
  color: #2c3e50;
  font-weight: 500;
  font-size: 18px;
  text-align: center;
}

.modalContent {
  padding: 10px;
  height: 100%;
  font-size: 14px;
  color: #2c3e50;
  text-align: center;
  overflow-y: auto;
  z-index: 99;
}

.modalContent > div {
  height: 100%;
}
.modalH .div-container {
  height: 100%;
}

.modalContent .div-content {
  max-height: 80%;
  scrollbar-width: thin;
  overflow: auto;
}

.modalActions {
  bottom: 2px;
  position: absolute;
  margin-bottom: 10px;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 10px;
  z-index: 100;
}

.actionsContainer {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  row-gap: 20px;
  column-gap: 20px;
  justify-content: space-evenly;
}

.closeBtn {
  cursor: pointer;
  font-weight: 500;
  padding: 4px 8px;
  border: none;
  font-size: 18px;
  color: #2c3e50;
  transition: all 0.25s ease;
  position: absolute;
  right: 0;
  top: 0;
  align-self: flex-end;
  border: none;
  outline: none;
  background: none;
}

.closeBtn:hover {
  color: black;

  svg {
    background: rgb(224 221 221);
    border-radius: 5px;
  }
}

.deleteBtn {
  cursor: pointer;
  font-weight: 500;
  padding: 10px 15px;
  border-radius: 12px;
  font-size: 0.8rem;
  border: none;
  color: #fff;
  background: #ff3e4e;
  transition: all 0.25s ease;
}

.deleteBtn:hover {
  box-shadow: 0 10px 20px -10px rgba(255, 62, 78, 0.6);
  transform: translateY(-5px);
  background: #ff3e4e;
}

.cancelBtn {
  cursor: pointer;
  font-weight: 500;
  padding: 10px 15px;
  border-radius: 12px;
  font-size: 0.8rem;
  border: none;
  color: #2c3e50;
  background: #c7c5c5;
  transition: all 0.25s ease;
}

.cancelBtn:hover {
  box-shadow: none;
  transform: none;
  background: whitesmoke;
}

.primaryBtn {
  cursor: pointer;
  font-weight: 500;
  padding: 10px 15px;
  border-radius: 15px;
  font-size: 0.8rem;
  border: none;
  color: white;
  background: #185adb;
  transition: all 0.25s ease;
}

.primaryBtn:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px -10px rgba(24, 90, 219, 0.6);
}

.secondaryBtn {
  cursor: pointer;
  font-weight: 500;
  padding: 10px 15px;
  border-radius: 15px;
  font-size: 0.8rem;
  border: none;
  color: #192616;
  background: #fcb83a;
  transition: all 0.25s ease;
}

.secondaryBtn:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px -10px #e3a022;
  background-color: #e3a022;
}

.disabled {
  cursor: not-allowed;
  background: rgba(0, 0, 0, 0.04);
  color: #2c3e50;
}
