.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 24px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 24px;
}

.slider.round:before {
  border-radius: 50%;
}

.checkbox-switch {
  display: flex !important;
  gap: 10px;
  flex-direction: row !important;
}

/* scrollbar */

::-webkit-scrollbar {
  width: 0.5em;
  border: 1px solid #d5d5d5;
}

::-webkit-scrollbar-track {
  border-radius: 3rem;
  background: #eeeeee;
}

::-webkit-scrollbar-thumb {
  border-radius: 3rem;
  background: #faac1ae1;
}

::-webkit-scrollbar-thumb:horizontal {
  background: #faac1ae1;
  border-radius: 3rem;
}

/* datepicker */
.date-input-container .MuiStack-root {
  overflow: hidden;
  padding: 0;
}

.date-input-container input[type="text"] {
  border: 0;
}

.date-input-container
  .MuiOutlinedInput-root:hover
  .MuiOutlinedInput-notchedOutline {
  border-color: #ccc;
}

.date-input-container .MuiFormControl-root {
  width: 100%;
}

.MuiPickersDay-root.Mui-selected {
  background-color: #fcb83a !important;
  color: #000 !important;
}

@media (max-width: 768px) {
  .date-input-container .MuiInputBase-root {
    padding: 1px;
  }
}

/* timepicker */

.MuiMultiSectionDigitalClockSection-root::after {
  display: block;
  content: "";
  height: 0 !important;
}

.MuiMenuItem-root.Mui-selected {
  background-color: #fcb83a !important;
  color: #000 !important;
}

.MuiList-root::-webkit-scrollbar-thumb {
  background-color: rgb(128, 128, 128, 0.5);
}

.MuiList-root::-webkit-scrollbar {
  width: 0.4rem;
}
