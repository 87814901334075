.table-list-container-mobile {
  background-color: #192616;
  padding: 20px 30px;
  border-radius: 20px;
  margin-top: 20px;
}

.table-list-mobile {
  background-color: white;
  border-radius: 20px;
  padding: 10px 20px;
  font-size: small;
}

.table-list-mobile .table-list-item-container {
  &:not(:last-child) {
    border-bottom: 1px solid rgb(0, 0, 1, 0.4);
  }

  padding-bottom: 10px;
}

.table-list-mobile .table-list-item {
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 5px;
}

.table-list-mobile .table-list-item > div {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.table-list-mobile .table-list-item-container:not(:first-child) {
  padding-top: 10px;
}

.table-title {
  color: #fcb83a;
}

.item-justify-end {
  justify-self: end;
  text-align: end;
}

.list-item-label {
  opacity: 0.7;
}

.table-list-action-buttons {
  display: flex;
  justify-content: center;
  gap: 40px;
  padding-top: 15px;
  padding-bottom: 5px;

  div {
    border: 2px solid #fcb83a;
    border-radius: 5px;
    padding: 2px;
    cursor: pointer;
  }

  div svg {
    transform: scale(0.8);
  }
}
