h1 {
  display: inline-block;
}

.timeline {
  display: flex;
  justify-content: center;
}

.timeline.desktop {
  background-color: #192616;
  padding: 5px;
  position: absolute;
  width: 100%;
  gap: 20px;
  padding: 20px;
  border-radius: 20px;
}

.timeline.mobile {
  padding-bottom: 10px;
  gap: 10px;
}

.timeline-icon {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;

  svg {
    font-size: small;
  }
}

.timeline-item.larger {
  font-size: larger;
  opacity: 0.8;
}

.timeline-item.large {
  font-size: large;
  opacity: 0.9;
}

.timeline-item.x-large {
  font-size: x-large;
  opacity: 1;
}

.timeline.desktop .timeline-icon {
  padding-top: 3px;
}

.timeline.desktop .timeline-icon {
  color: #ddd;
}

.timeline.desktop .timeline-item {
  color: #fcb83a;
}

.timeline-icon.mobile {
  border-radius: 50%;
  background: grey;
}

.timeline-icon.mobile.selected {
  background-color: #fcb83a;
}

.timeline-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  text-transform: capitalize;
  cursor: pointer;
  transition: font-size 0.2s ease-in-out;
}

.mobile .timeline-item {
  font-size: small;
  gap: 10px;
}

.rides-list {
  padding: 10px 20px;
  background-color: #ddd;
  border-radius: 20px;
  overflow-y: auto;
  height: 86%;
  scrollbar-width: thin;
}

.back .rides-list {
  height: 75%;
}

.mobile .rides-list {
  padding: 0;
  background-color: white;
}
.mobile .front .rides-list {
  height: 55%;
}

.all-rides {
  position: relative;
  height: 100%;
}

.all-rides.mobile {
  flex: 1;
  overflow: hidden;
}

.rides-container {
  position: absolute;
  border-radius: 20px;
  padding: 20px 30px;
  width: 100%;
}

.rides-container.back {
  height: 50%;
  background-color: #fcb83a;
  z-index: 0;
  transition: height 0.5s ease-in-out;
}

.rides-container.back.open {
  z-index: 1;
  height: 100%;
}

.rides-container.front {
  background-color: #192616;
  left: 0;
  height: 100%;
}

.mobile .rides-container.front {
  top: 40%;
  transition: top 0.5s ease-in-out;
}

.rides-container.desktop.front {
  height: 55%;
  top: 56%;
  transition: top 0.5s ease-in-out, height 0.5s ease-in-out;
}

.rides-container.desktop.front.open {
  height: 100%;
  top: 14vh;
}

.mobile .rides-container.front.open {
  height: 100%;
  top: 0;
}

.mobile .rides-container.open {
  .rides-list {
    height: 90%;
  }
}

.circle-icon {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  text-align: center;
  justify-content: center;
  align-content: center;

  svg {
    width: 100%;
    height: 100%;
    transform: scale(0.7);
  }
}

.circle-COMPLETED svg {
  transform: scale(1);
}

.rides-container.desktop.back {
  top: 14vh;
}

.rides-title {
  text-transform: capitalize;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
}

.payment-status span {
  text-transform: capitalize;
}

.rides-container.back .rides-title {
  color: #192616;
}
.rides-container.front .rides-title {
  color: #fcb83a;
}

.mobile .ride-item-container {
  cursor: pointer;
  padding: 0 20px;
}

.mobile .rides-container.back .ride-item-container:hover {
  background-color: #fcb83a1a;
}

.mobile .rides-container.front .ride-item-container:hover {
  background-color: #1926161c;
}

.ride-item-container {
  border-bottom: 1px solid rgb(0, 0, 1, 0.4);
  padding-bottom: 10px;
}

.ride-item {
  display: flex;
  gap: 20px;
  justify-content: space-between;
  padding-bottom: 10px;
  align-items: center;
  padding-top: 10px;
  font-size: small;
}

.mobile .ride-item {
  gap: 5px;
}

.ride-item .itinerary-info {
  display: flex;
  flex-direction: column;

  .bottom {
    opacity: 0.7;
    padding-top: 3px;
  }
}

.ride-item .itinerary-container {
  display: flex;
  gap: 20px;
  align-items: center;
}

.ride-item-container-desktop {
  display: flex;
  padding-bottom: 10px;
}

.ride-item-container-desktop .itinerary-info {
  display: flex;
  gap: 30px;
  justify-content: space-around;
  width: 100%;
}

.ride-item-container-desktop .ride-item-desktop {
  display: flex;
  text-align: center;
  padding: 10px;
  flex-grow: 2;
  flex-direction: column;
}

.ride-item-container-desktop .ride-item-desktop:first-child {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  width: 35%;
}

.ride-item-container-desktop .ride-item-desktop:last-child {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  width: 35%;
}

.ride-item-container-desktop .ride-item-desktop:nth-child(2) {
  flex-grow: 1;
  justify-content: space-between;
}

.ride-item-info {
  display: flex;
  justify-content: space-between;
  padding-bottom: 2px;
}

.circle-ACCEPTED {
  background-color: #046e1ba6;
}

.circle-REJECTED {
  background-color: #ff0000e6;
}

.circle-UNCONFIRMED {
  background-color: #ff7b00;
}

.circle-COMPLETED {
  background-color: #007bffb8;
}

.type-ACCEPTED {
  color: #046e1b;
}

.type-REJECTED {
  color: #ff7b00;
}

.type-UNCONFIRMED {
  color: #ff0000;
}

.type-COMPLETED {
  color: #046e1b;
}

.ride-item-buttons {
  display: flex;
  justify-content: center;
  gap: 10px;
  padding: 10px 0;
  button {
    text-transform: uppercase;
    font-size: small;
  }
}

.front .ride-item-desktop {
  font-size: x-small;
  font-weight: 600;
  border: 2px solid #192616;
}

.back .ride-item-desktop {
  font-size: small;
  align-items: center;
  justify-content: space-between;
  border: 2px solid #fcb83a;
  gap: 10px;
}

.scheduled-rides-container {
  display: flex;
  flex: 1;
}

.scheduler-container {
  width: 30%;
  height: inherit;
  background-color: #192616;
  display: flex;
  flex-direction: column;
  padding: 15px;
}

.scheduler-container.mobile .nav-item {
  height: 25%;
}

.scheduler-container.mobile .dropdown-menu {
  overflow: scroll;
}

.scheduler-container .dropdown-menu {
  width: 100%;
  height: 100%;
}

.scheduler-container.hidden {
  display: none;
}

.scheduler-container.mobile {
  position: absolute;
  width: 100%;
  z-index: 2;
  height: 100%;

  .close-icon {
    color: white;
    cursor: pointer;
    align-self: flex-end;
  }
}

.client-booking-mobile-container {
  flex: 1;
  position: relative;
  display: flex;
  flex-direction: column;
}

.all-rides-container {
  width: 100%;
  height: inherit;
  padding: 30px 8%;
  overflow: hidden;
}

.schedule-title,
.statistics-title {
  color: #fcb83a;
  align-self: center;
}

.schedule-title {
  font-size: x-large;
  font-weight: 800;
}

.statistics-title {
  font-size: larger;
  font-weight: 800;
  text-align: center;
}

.schedule-info,
.statistics-info {
  color: white;
}

.schedule-info {
  padding: 20px 0;
  text-align: center;
}

.statistics-info {
  padding-top: 20px;
  padding-bottom: 40px;
}

.statistics-info-detail {
  display: flex;
  justify-content: space-between;
  padding: 0 10%;
}

.highlighted {
  background: #fcb83a;
  border-radius: 2px;
}

.grey {
  color: #676666;
}

.section-ride-title {
  font-size: small;
}

.schedule-title-mobile {
  text-align: center;
  margin-top: 10px;
}

.button-container {
  display: flex;
  justify-content: center;

  button {
    padding: 10px 20px;
    border-radius: 10px;
  }
}

.links-section-title {
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
  color: #fcb83a;
  font-weight: 700;
}
