/* General Reset */
body, h1, h2, p, a {
  margin: 0;
  padding: 0;
  font-family: 'Arial', sans-serif;
}

.landing-page {
  background-color: #f9f9f9;
  color: #222;
  line-height: 1.6;
}

/* Hero Section */
.hero-section {
  position: relative;
  background-image: url('./hero-bg.jpeg'); /* Replace with your image */
  background-size: cover;
  background-position: center;
  height: 500px;
  color: #fff; /* Text color */
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.hero-section::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Adjust color and opacity */
  z-index: 1; /* Position overlay behind text */
}

.hero-content {
  max-width: 600px;
  z-index: 2;
}

.hero-content h1 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  font-weight: bold;
  color: #fcb83a;
}

.hero-content p {
  font-size: 1.2rem;
  margin-bottom: 1.5rem;
}

.hero-content .search-button {
  background-color: #fcb83a;
  color: #192616;
  padding: 0.75rem 1.5rem;
  border-radius: 5px;
  text-decoration: none;
  font-weight: bold;
}

.hero-content .search-button:hover {
  background-color: #e3a022;
}

/* Card Section */
.section-title {
  text-align: center;
  margin: 2rem 0 1rem;
  font-size: 2rem;
  font-weight: bold;
  color: #192616;
}

.cards-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  padding: 2rem;
}

.card {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
  padding: 1.5rem;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.card:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
}

.card-image {
  width: 100px;
  height: 100px;
  margin: 0 auto 1rem;
}

.card-title {
  font-size: 1.5rem;
  font-weight: bold;
  color: #192616;
  margin-bottom: 0.5rem;
}

.card-description {
  font-size: 1rem;
  color: #555;
  margin-bottom: 1rem;
}

.card-button {
  display: inline-block;
  background-color: #fcb83a;
  color: #192616;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  text-decoration: none;
  font-weight: bold;
}

.card-button:hover {
  background-color: #e3a022;
}
