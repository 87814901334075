.button {
  background-color: #08121d; /* Blue background color */
  color: #fff; /* White text color */
  padding: 5px 10px; /* Adjust padding as needed */
  border: none;
  border-radius: 4px; /* Rounded corners */
  cursor: pointer;
  transition: background-color 0.3s; /* Smooth transition on hover */
  margin: 2px;
  /* Add any other common styles here */
}

.ride-button {
  background: white;
  color: #08121d;
  border: 1px solid #fcb83a;
}

.button.ride-button:hover {
  background-color: #d79105; /* Darker blue on hover */
}

.ride-button.active {
  background: #fcb83a;
  color: #08121d;
  border: 1px solid #fcb83a;
}

/* Confirm Button Style */
.confirm-button {
  background-color: #28a745; /* Green background color for confirmation */
  /* You can customize other styles for the confirmation button as needed */
}

/* Delete Button Style */
.delete-button {
  background-color: #dc3545; /* Red background color for delete actions */
  /* You can customize other styles for the delete button as needed */
}

/* Warning Button Style */
.warning-button {
  background-color: #dca435; /* Yellow background color for warning actions */
  /* You can customize other styles for the delete button as needed */
}

/* Accept Button Style */
.accept-button {
  background-color: #08121d; /* Yellow background color for accept actions */
  /* You can customize other styles for the delete button as needed */
  color: white;
}

/* Decline Button Style */
.decline-button {
  background-color: #dca435; /* Black background color for accept actions */
  /* You can customize other styles for the delete button as needed */
  color: #08121d;
}

/* Hover effect for all buttons */
.button:hover {
  background-color: #07284a; /* Darker blue on hover */
}

.button.confirm-button:hover {
  background-color: #00841f; /* Darker blue on hover */
}

.button.delete-button:hover {
  background-color: #a10010; /* Darker blue on hover */
}

.button.warning-button:hover {
  background-color: #d79105; /* Darker blue on hover */
}

.submit-button {
  background-color: #fcb83a;
  color: #192616;
  padding: 0.75rem 1.5rem;
  border-radius: 5px;
  text-decoration: none;
  font-weight: bold;
}

.submit-button:hover {
  background-color: #e3a022;
}
