.div-container {
  display: flex;
  align-items: stretch;
}

.div-content.client-card-big {
  /* justify-content: center; */
  background-color: #fff;
  margin: 1.5em;
  padding: 5ex;
  border: 1px solid #ddd;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 30px;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-self: center;
}

.div-content.client-card-big:not(.booking-system) {
  max-height: 600px;
}

.client-card-big img {
  max-width: 100%;
}

select.minimal {
  /* styling */
  background-color: white;
  border: thin solid;
  border-radius: 4px;
  display: inline-block;
  font: inherit;
  line-height: 1.5em;
  padding: 0.5em 3.5em 0.5em 1em;

  /* reset */

  margin: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

select.minimal:focus {
  background-image: linear-gradient(45deg, rgb(203, 87, 5) 50%, transparent 50%),
    linear-gradient(135deg, transparent 50%, rgb(203, 87, 5) 50%),
    linear-gradient(to right, #ccc, #ccc);
  background-position: calc(100% - 15px) 1em, calc(100% - 20px) 1em,
    calc(100% - 2.5em) 0.5em;
  background-size: 5px 5px, 5px 5px, 1px 1.5em;
  background-repeat: no-repeat;
  border-color: rgb(203, 87, 5);
  outline: 0;
}

select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #000;
}

label.bookatrip {
  display: block;
  margin-top: 5px;
}

input[type="date"] {
  width: 100%;
}

.smooth-transition-button {
  margin-top: 15px;
  transition: margin ease-in-out;
}

.show-button {
  background-color: #fcb83a;
  color: #192616;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  text-decoration: none;
  font-weight: bold;
  width: 100%;
}

.show-button:hover {
  background-color: #e3a022;
}

@media (max-width: 700px) {
  .div-content {
    width: 100%;
  }
}

@media (min-width: 700px) {
  .div-content {
    width: 550px;
  }
}

.booking-system,
.passenger-info {
  padding-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.passenger-info h4 {
  background-color: #fff2e0;
  padding: 5px;
}

.select-dropdown {
  margin-left: 10px;
}

.search-clients {
  background-image: url(background-sidebar-left.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 22%;
  padding: 1.2rem;
}

.book-a-trip {
  flex: 1;
  position: relative;
}

.client-list {
  background-image: url(background-grey-road-opacity.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  padding: 1.2rem;
  width: 78%;
}

.search-label {
  color: #ddd;
  margin-bottom: 1rem;
}

.client-list .search-input-container {
  display: none;
}

@media (max-width: 768px) {
  .search-clients {
    display: none;
  }

  .client-list {
    width: 100%;
  }

  .client-list .search-input-container {
    display: flex;
    margin-top: 1rem;
  }
}

.client-card-container {
  background-image: url(background-green-road.jpg);
  background-position: center;
  background-attachment: fixed;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  padding: 0 10px;
  flex: 1;
}

.opacity-div {
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: 0.5;
  background: #363636;
}

.spinner-container {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #0000009c;
}

.spinner-container svg {
  color: #fcb83a;
}

.textarea-width {
  width: 100%;
  display: block;
}
