.react-select-container {
  width: 100%;
}

.react-select__menu-list::-webkit-scrollbar-thumb {
  background-color: rgb(128, 128, 128, 0.5);
}

.react-select__menu-list::-webkit-scrollbar {
  width: 0.4rem;
}

.react-select__menu-list {
  max-height: 200px !important;
}
