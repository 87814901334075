/* ClientManagement.css */

.client-management {
  font-family: Arial, sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  flex-direction: column;
  background-color: #f5f5f5;
  width: 100%;
  background: #fff;
  padding: 20px 30px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  box-sizing: border-box;
  flex-grow: 1;
}

.client-management h1 {
  align-self: flex-start;
}

.client-management .subtitle {
  align-self: flex-start;
  font-style: italic;
  padding-bottom: 10px;
  color: #333;
}

.client-management h2 {
  text-align: center;
  color: #333;
  margin-bottom: 20px;
}

div.form-groups-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px;
}

.form-group.active-checkbox {
  grid-column-start: 1;
  grid-column-end: 3;
}

@media screen and (max-width: 768px) {
  div.form-groups-container {
    grid-template-columns: 1fr;
  }

  .form-group.active-checkbox {
    grid-column-start: 1;
    grid-column-end: 1;
  }
}

.form-groups-container div {
  display: flex;
  flex-direction: column;
}

label {
  font-weight: bold;
  color: #333;
  margin-bottom: 5px;
  position: relative;
}

label .required {
  color: red;
  margin-left: 5px;
}

input[type="text"],
input[type="email"],
input[type="phone"],
input[type="checkbox"],
input[type="password"] {
  padding: 8px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
  transition: border-color 0.3s ease;
  width: 100%;
  display: inline;
}

input[type="text"]:focus,
input[type="email"]:focus,
input[type="phone"]:focus {
  border-color: #007bff;
}

input[type="checkbox"] {
  width: auto;
  margin-left: 5px;
}

.description {
  font-size: 12px;
  color: #777;
  font-style: italic;
  margin-top: 5px;
}

form p {
  font-size: 14px;
  color: #555;
}

.submit-button-container {
  display: flex;
  justify-content: center;
  align-content: center;
}

@media (max-width: 768px) {
  .submit-button-container {
    position: sticky;
    bottom: 0;
    background: #fff;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    padding: 10px;
    box-shadow: 0px -4px 7px rgb(150 137 137 / 75%);
  }

  .client-management {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}

.management-container {
  padding: 0.5rem 1.2rem;
  flex: 1;
}
