.table {
  table {
    border-collapse: separate;
    border-spacing: 0 10px;
  }
  td,
  th {
    padding: 10px 30px;
    /* text-align: center; */
  }

  thead tr {
    background-color: #fcb83a;

    th:not(.no-border) {
      padding: 15px 30px;
      border-right: 1px solid lightgrey;
    }

    th:first-of-type {
      border-top-left-radius: 10px;
    }

    th:last-of-type {
      border-top-right-radius: 10px;
    }
  }

  tbody tr {
    background-color: #f9f9f98a;
  }

  .action-button-container {
    width: 12%;
  }

  tbody tr td:nth-last-child(2) {
    border-left: 1px solid #08121d5c;
  }

  .action-button {
    cursor: pointer;
    padding: 2px;
    border: 2px solid transparent;
    text-align: center;
    width: 40px;
  }

  .action-button:hover {
    border-radius: 5px;
    background: #fcb83a26;
  }
}

.table-container {
  padding: 10px;
}

.add-new-button {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin: 10px 0 5px 0;
}
