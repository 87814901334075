
  .list-container {
    list-style-type: none;
    padding: 0;
  }

  /* Style for each list item */
  .list-item {
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    border: 2px solid #ccc; /* Border for each list item */
    border-radius: 25px;
    padding: 1%;
    transition: border-color 0.3s; /* Smooth transition on hover */
    background-color: #cccccc23;
  }

  /* Style for the rounded image */
  .list-item img {
    border-radius: 50%;
    width: 50px; /* Adjust the width as needed */
    height: 50px; /* Adjust the height as needed */
    margin-right: 10px; /* Adjust the spacing between image and text */
    border: 2px solid #ccc;
    background-color: #ccc;
  }

  /* Style for the text */
  .list-item p {
    margin: 0;
  }

  /* Style for list item on hover */
  .list-item:hover {
    border-color: #007bff; /* Change border color on hover */
    background-color: #007bff29;
  }