/* Navbar General Styling */
.navbar {
  background-color: #192616;
  color: #fff;
  padding: 0.5rem 1rem;
}

.navbar-brand img {
  border-radius: 15px;
}

.navbar-toggler {
  border: none;
  outline: none;
}

.navbar-toggler:focus {
  box-shadow: none;
}

.nav-link {
  color: #fff !important;
  font-size: 1rem;
  margin-right: 1rem;
  transition: color 0.3s ease;
}

.nav-link:hover {
  color: #fcb83a !important;
}

.nav-link-management {
  color: #fcb83a !important;
}

.dropdown-menu {
  background-color: #192616;
  border: none;
  border-radius: 5px;
}

.dropdown-item {
  color: #fff;
  font-size: 1rem;
}

.dropdown-item:hover {
  background-color: #fcb83a;
  color: #192616;
}

.navbar-toggler-icon {
  filter: brightness(0) invert(1);
}

.cta-button {
  background-color: #fcb83a;
  color: #192616;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  text-decoration: none;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.cta-button:hover {
  background-color: #e3a022;
  color: #192616;
}

/* Active Page Styling */
.nav-link.active,
.dropdown-item.active {
  color: #fcb83a !important;
  font-weight: bold;
  border-bottom: 2px solid #fcb83a;
}

.dropdown-item.active {
  background-color: #fcb83a;
  color: #192616 !important;
}

/* Footer Styling */
.footer {
  background-color: #192616;
  color: #fff;
  text-align: center;
  padding: 1rem 0;
  font-size: 0.9rem;
  margin-top: auto; /* Pushes footer to the bottom */
}

.container-fluid {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Ensure the container takes full viewport height */
  padding-right: 0;
  padding-left: 0;
}

.navbar {
  z-index: 998; /* Ensures the navbar stays on top */
}

.main-content {
  flex-grow: 1; /* Takes up the remaining space to push footer down */
}

/* Login Icon Styling */
.login-icon {
  color: #fff;
  margin-right: 1rem;
  cursor: pointer;
  text-decoration: none;
  transition: color 0.3s ease;
}

.login-icon:hover {
  color: #fcb83a;
}

.login-icon.visible {
  display: block;
  margin: 0 auto; /* Center the icon in the navbar */
  margin-top: 0.5rem;
}

@media (max-width: 992px) {
  .navbar-nav {
    text-align: center;
  }

  .dropdown-menu {
    text-align: center;
  }

  .login-icon {
    display: none; /* Hide by default on smaller screens */
  }

  /* Show the login icon when the navbar is open */
  .login-icon.visible {
    display: block;
    margin: 0 auto; /* Center the icon in the navbar */
    margin-top: 0.5rem;
    justify-content: center;
    align-items: center;
  }
}
