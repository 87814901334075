.div-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  /* height: 100vh; */
}

.div-content {
  /* justify-content: center; */
  background-color: #fff;
  margin: 1.5em;
  padding: 5ex;
  border: solid;
  border-radius: 30px;
}

select.minimal {
  /* styling */
  background-color: white;
  border: thin solid;
  border-radius: 4px;
  display: inline-block;
  font: inherit;
  line-height: 1.5em;
  padding: 0.5em 3.5em 0.5em 1em;

  /* reset */

  margin: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

select.minimal:focus {
  background-image: linear-gradient(45deg, rgb(203, 87, 5) 50%, transparent 50%),
    linear-gradient(135deg, transparent 50%, rgb(203, 87, 5) 50%),
    linear-gradient(to right, #ccc, #ccc);
  background-position: calc(100% - 15px) 1em, calc(100% - 20px) 1em,
    calc(100% - 2.5em) 0.5em;
  background-size: 5px 5px, 5px 5px, 1px 1.5em;
  background-repeat: no-repeat;
  border-color: rgb(203, 87, 5);
  outline: 0;
}

select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #000;
}

label.bookatrip {
  display: block;
  margin-top: 5px;
}

@media (max-width: 700px) {
  .div-content {
    width: 100%;
  }
}

@media (min-width: 700px) {
  .div-content {
    width: 550px;
  }
}
