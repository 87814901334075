@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&display=swap");

* {
  box-sizing: border-box;
}

html {
  font-family: "Noto Sans JP", sans-serif;
}

body {
  margin: 0;
  background-color: #3f3f3f29;
  /* background-image: url("../public/transfero_background.png"); */
  background-repeat: inherit;
  background-size: auto;
  height: 100%;
  width: 100%;
  position: center;
}

.react-select__control {
  cursor: pointer !important;
}

.react-select__option {
  background-color: white !important;
}

.react-select__option--is-selected {
  background-color: #fcb83a !important;
  color: #000 !important;
}

.react-select__option:not(.react-select__option--is-selected):hover {
  background-color: rgb(252, 184, 58, 0.1) !important;
}
